body {
	background: #0b1426;
}


.header {
                background: #171e30!important;
            }

            .navbar-brand {
                width: 10%;
            }

            .header  .active {
                color: #fff!important;
            }

            .header .nav-link {
                color: #fff;
            }

.form-section {
    background: #1b202b;
  
    border-radius: 10px;
    
}

.form-inner1 {
	padding: 100px 0;
}


.header .btn .btn-primary {
background-image: linear-gradient(to right, #4c9dda , #496fc8);
}


 .form-inner {
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 0;
}

.form-section a {
    text-decoration: none;
}
 .logo img {
    margin-bottom: 15px;
    height: 35px;
}

 .form-section h1 {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    text-align: left;
}

 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.b_text {
    line-height: 25px;
    margin:20px 0;
}

 .form-section .form-group {
    margin-bottom: 25px;
}

.form-section .form-control {
    padding: 14.5px 15px;
    font-size: 16px;
    outline: none;
    background: #2a3040!important;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    border: solid 1px #282f3f;
}

.form-section .form-select {
    padding: 14.5px 15px;
    font-size: 16px;
    outline: none;
    background: #2a3040;
    color: #a0a9b2;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    border: solid 1px #282f3f;
    font-size: 12px;
    font-weight: 500;
}

.form-section select {
	background: #ccc;
}

.form-section .form-control::placeholder {
    color: #a0a9b2;
    font-size: 12px;
    font-weight: 500;
}

 .form-section .form-box i {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 23px;
    color: #777575;
}

hr {
	border-color: rgb(255 255 255 / 38%);
}

 .form-section .checkbox {
    margin: 10px 0 35px;
}

 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 25px;
    font-size: 16px;
    color: #424242;
}

 .form-section .form-check .form-check-input {
    margin-left: -25px;
    border-radius: 2px;
    color: #fff;
}

.form-section label {
	color: #fff;
}

 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    vertical-align: top;
    position: absolute;
    border: 2px solid #c5c3c3;
    border-radius: 0;
    top: 2px;
}


 .form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 25px;
    font-size: 16px;
    color: #35456f;
}

 .form-section .form-group {
    margin-bottom: 25px;
}

.login-1 .btn-primary {
    background: #1c5fda;
}
.login-1 .btn {
    box-shadow: none!important;
}

.login-1 .btn-lg {
    padding: 0 50px;
    line-height: 55px;
}

 .btn-theme {
    position: relative;
   
    width: 100%;
    color: #ffffff;
   
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    border: none;
    background-image: linear-gradient(to right, #4c9dda , #496fc8);
}

 .btn-theme span {
    position: relative;
    z-index: 1;
}

 .btn-lg {
    padding: 0 50px;
    line-height: 55px;
}

 .form-section .form-group {
    margin-bottom: 25px;
}

 .form-section .extra-login {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 10px 0 35px!important;
}

 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 13px;
    width: 100%;
    height: 1px;
    background: #ffffff;
    content: "";
}

 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    background: #1b202b;
}
 .form-section .extra-login {
    float: left;
    width: 100%;
    text-align: center;
    /* position: relative; */
    margin: 10px 0 35px!important;
}

 

 .form-section a {
    text-decoration: none;
}

 .social-list a i {
    transition: transform 0.2s linear 0s;
    position: relative;
    z-index: 3;
}

 .form-section p {
    color: #fff;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
}

 .form-section .thembo {
    margin-left: 4px;
}

 .form-section p a {
    color: #fff;
}

 .form-section a {
    text-decoration: none;
}

.form-inner .g_btn {
    background: #fff;
    border: 0;
    border-radius: 2px;
    width: 100%;
    padding: 15px 0;
    background-image: linear-gradient(to right, #4c9dda , #496fc8);
    color: #fff;
font-weight: 700;
margin-bottom: 20px;
margin-top: 20px;
}

.form-inner .g_btn i {
    margin-right: 10px;
    font-size: 20px;
}

.qr_box {
    text-align: center;
    padding: 15px;
}
.qr_box h3 {
    color: #fff;
    font-size: 18px;
}
.qr_box img {
    width: 40%;
    margin: 20px 0;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

.form-section {
	padding: 30px;
}

.sign_heading {
	    font-size: 30px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    padding: 20px 30px;
    border-bottom: 1px solid rgb(255 255 255/.1);
}

.form_inner_heading {
	color: #fff;
	text-align: left;
	font-size: 20px;
	margin-bottom: 15px;
}


.pl-0 {
	padding: 0!important;
}


