


/*buttons*/

.btn-primary {
    border: 0;
    border-radius: 2px;
 }
 
 .dropdown .btn-secondary {
    background: inherit;
    border:0;
 }
 
 /*buttons*/
 
 
 .profile .dropdown-menu[data-bs-popper] {
    right: 0;
    left: inherit;
 }
 
 .profile .dropdown-menu li {
     width: 350px;
     padding: 5px 20px;
     margin-bottom: 10px;
 }
 
 .profile .dropdown-menu li:hover {
    background: #171e30;
    padding: 5px 20px;
 }
 .profile .dropdown-menu li:nth-child(1):hover{
    background: inherit;
 }
 
 .profile .dropdown-menu li:nth-child(5):hover{
    background: inherit;
 }
 
 .profile-img {
     background: #171e30;
     height: 40px;
     width: 45px;
     text-align: center;
     line-height: 31px;
     border-radius: 10px;
 }
 .profile .dropdown-item:hover{
    background: inherit;
 }
 
 .profile .profile-img img {
    height: 20px;
    width: 20px;
 }
 .profile .dropdown-item {
    font-weight: 500;
 }

/* Start Exchange */

body {
   
    font-size: 14px;
    line-height: 1.5;
    color: #18214D;
    background: #FFFFFF;
    font-weight: 400;
}

a,
a:hover {
    text-decoration: none;
    transition: 0.3s;
    color:#fff;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

.mtb100 {
    margin: 100px 0;
}

.mtb15 {
    margin: 15px 0;
}

.mt15 {
    margin-top: 15px;
}

.mb15 {
    margin-bottom: 15px;
}

.ml15 {
    margin-left: 15px;
}

.mr15 {
    margin-right: 15px;
}

.mb30 {
    margin-bottom: 30px;
}

.ptb70 {
    padding: 70px 0;
}

.pt70 {
    padding-top: 70px;
}

.pb40 {
    padding-bottom: 40px;
}

::-webkit-scrollbar {
    background: transparent;
    width: 3px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.07);
    border: solid rgba(88, 88, 88, 0.12) 2px;
    border-radius: 5px;
}

.dropdown-toggle::after {
    vertical-align: 1px;
}

thead tr th {
    padding: 8px 15px !important;
}

thead th {
    color: #758696;
    font-size: 13px;
    font-weight: 600;
    border: 0 !important;
}

tbody tr {
    color: #4a4a4a;
}

tbody tr:hover {
    background: #f6f8f9;
}

.input-group-text,
.form-control {
    border-radius: 5px;
}

.table tbody + tbody {
    border: none;
}

.heading {
    background: #f5f9fc;
    font-size: 14px;
    font-weight: 400;
    padding: 13px;
    margin: 0;
    color: #4a4a4a;
}

.green {
    color: #26de81;
}

.red {
    color: #ff231f;
}

.table td,
.table th {
    border-top: 1px solid #f0f3fa;
}

.light-bb,
.input-group-text {
    border: 1px solid #e0e3eb;
}


#darkDepthChart,
#lightDepthChart {
    width: 100%;
    height: 349px;
}

.sm-gutters {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.sm-gutters > .col,
.sm-gutters > [class*='col-'] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #18214d59;
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #18214d59;
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #18214d59;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #18214d59;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.btn-1,
.btn-2 {
    display: inline-block;
    background: transparent;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 5px;
    padding: 9px 25px;
    margin-left: 15px;
}

.btn-1:hover,
.btn-2:hover {
    background: #0069d9;
    color: #fff;
    text-decoration: none;
}

.btn-2 {
    background: #007bff;
    color: #fff;
}

.bt-none {
    border-top: none !important;
}













.market-pairs {
    border: 1px solid #e0e3eb;
    padding-top: 14px;
    border-radius: 2px;
}

.market-pairs .input-group {
    padding: 0 14px;
}

.market-pairs span {
    background: transparent;
}

.market-pairs input {
    border-color: #e0e3eb;
    border-left: 0;
    font-weight: 300;
}

.market-pairs input:focus {
    box-shadow: none;
    border-color: #e0e3eb;
}

.market-pairs .nav {
    margin-top: 14px;
    background: #f5f9fc;
}

.market-pairs .nav-link {
    color: #4a4a4a;
    padding: 10px 13px;
}

.market-pairs .nav-link.active {
    color: #007bff;
    background: transparent;
}

.table {
    margin-bottom: 0;
}

.table td {
    font-size: 13px;
    padding: 10px 18px;
}

.market-pairs tbody tr td i {
    color: #75869696;
}

#STAR i {
    color: #007bff;
}

.market-pairs thead tr th,
.market-pairs tbody tr td {
    cursor: pointer;
    font-weight: 400;
}

.market-pairs tbody {
    height: 800px;
    overflow-y: auto;
    display: block;
}

.no-fluid .market-pairs tbody {
    height: 815px;
}

.market-pairs table,
.market-pairs tr {
    width: 100%;
    table-layout: fixed;
}

.market-pairs tr {
    float: left;
    clear: both;
    overflow: hidden;
}

.market-pairs thead th,
.market-pairs tbody td {
    width: 65%;
}

.order-book table,
.order-book tr,
.order-book td,
.order-book th {
    width: 100%;
    table-layout: fixed;
}

.order-book tr {
    float: left;
    clear: both;
    overflow: hidden;
}

.order-book .table thead th {
    font-weight: 400;
    font-size: 12px;
}

.order-book thead tr {
    border-bottom: 1px solid #e0e3eb;
}

.order-book tbody tr,
.order-book tbody td {
    border: none;
}

.order-book {
    border: 1px solid #e0e3eb;
    border-radius: 2px;
}

.red-bg-80,
.red-bg-60,
.red-bg-40,
.red-bg-20,
.red-bg-10,
.red-bg-8,
.red-bg-5,
.red-bg,
.green-bg-80,
.green-bg-60,
.green-bg-40,
.green-bg-20,
.green-bg-10,
.green-bg-8,
.green-bg-5,
.green-bg {
    position: relative;
}

.red-bg-80:after,
.red-bg-60:after,
.red-bg-40:after,
.red-bg-20:after,
.red-bg-10:after,
.red-bg-8:after,
.red-bg-5:after,
.red-bg:after {
    position: absolute;
    content: "";
    background: #de262663;
    right: 0;
    top: 0;
    width: 280px;
    height: 45px;
}

.red-bg-60:after {
    width: 240px;
}

.red-bg-40:after {
    width: 200px;
}

.red-bg-20:after {
    width: 160px;
}

.red-bg-10:after {
    width: 120px;
}

.red-bg-8:after {
    width: 80px;
}

.red-bg-5:after {
    width: 50px;
}

.red-bg:after {
    width: 30px;
}

.green-bg-80:after,
.green-bg-60:after,
.green-bg-40:after,
.green-bg-20:after,
.green-bg-10:after,
.green-bg-8:after,
.green-bg-5:after,
.green-bg:after {
    position: absolute;
    content: "";
    background: #26de8163;
    right: 0;
    top: 0;
    width: 280px;
    height: 45px;
}

.green-bg-60:after {
    width: 240px;
}

.green-bg-40:after {
    width: 200px;
}

.green-bg-20:after {
    width: 160px;
}

.green-bg-10:after {
    width: 120px;
}

.green-bg-8:after {
    width: 80px;
}

.green-bg-5:after {
    width: 50px;
}

.green-bg:after {
    width: 30px;
}

.ob-heading span {
    color: #4a4a4a;
    display: block;
    font-size: 12px;
    font-weight: 400;
}

.ob-heading td {
    padding: 5px 12px;
    cursor: default;
}

.ob-heading tr {
    border-top: 1px solid #f0f3fa !important;
    border-bottom: 1px solid #f0f3fa !important;
}

.ob-heading tr:hover {
    background: #fff;
}

.ob-heading td:first-child {
    width: 65%;
}

.order-book tbody {
    display: block;
    overflow-y: auto;
    height: 211px;
}

tbody.ob-heading {
    height: inherit;
}

.market-trade input {
    border: 1px solid #e0e3eb;
    font-weight: 300;
}

.market-trade input:focus {
    box-shadow: none;
    border-color: #e0e3eb;
}

.market-trade {
    border: 1px solid #e0e3eb;
    border-radius: 2px;
}

.market-trade .tab-content {
    height: 357px;
    overflow-y: auto;
    padding: 14px;
}

.market-trade .nav {
    padding: 0px 14px 0;
}

.market-trade .nav .nav-item .nav-link.active {
    background: #fff;
    color: #007bff;
}

.market-trade .nav .nav-item .nav-link {
    color: #4a4a4a;
    
}

#dark .no-data i,
#dark .no-data {
    color: #2a2e39;
}


.market-trade .input-group-text {
    background: #eff2f6;
    color: #4a4a4a;
    font-weight: 300;
}

.market-trade .input-group {
    margin-bottom: 14px;
}


.market-trade-list li a {
    background: #eff2f6;
    color: #4a4a4a;
    font-weight: 300;
    padding: 3px 15px;
    border-radius: 5px;
}

.market-trade-list li {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
}

.market-trade-list li:last-child {
    margin-right: 0;
}

.market-trade-buy .market-trade-list li a:hover {
    text-decoration: none;
    background: #26a69a;
    color: #fff;
}

.market-trade-sell .market-trade-list li a:hover {
    text-decoration: none;
    background: #ef5350;
    color: #fff;
}

.market-trade button.buy,
.market-trade button.sell {
    background: #26de81;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    margin-top: 30px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px #26de81ad;
}

.market-trade button.sell {
    background: #ff231f;
    box-shadow: 0px 0px 15px 0px #ff231fad;
}

.market-trade p {
    margin: 5px 0;
    font-size: 13px;
    color: #4a4a4a;
}


.market-trade p span {
    float: right;
}

.market-trade-usdt {
    margin-top: 20px;
}

.market-trade-usdt li {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
}
.market-trade-list {
    margin-bottom: 23px;
}

.market-trade-usdt li a {
    background: #2a304080;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 500;
}

.btn_meta {
    /* background: #f6851b; */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #f6851b;
    display: block;
}


.btn_meta  img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.btn_wallet  img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.btn_link  img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.btn_wallet {
    /* background: #3375bb; */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #3375bb;
    display: block;
}

.btn_link {
    /* background: #135dcd; */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #135dcd;
    display: block;
}

.market-trade-usdt  li img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.market-trade-item {
    width: 100%;
}

.market-trade-buy,
.market-trade-sell {
    width: 100%;
}

.market-trade-buy {
    padding: 0 15px 0 0;
}

.market-trade-sell {
    padding: 0 0 0 15px;
    border-left: 1px solid #e0e3eb;
}




.market-history .nav-link.active {
    color: #007bff;
    background: transparent;
}

.market-history thead tr th,
.market-history tbody tr td {
    cursor: pointer;
    font-weight: 400;
}

.market-history tbody {
    height: 315px;
    overflow-y: auto;
    display: block;
}

.market-history table,
.market-history tr,
.market-history td,
.market-history th {
    width: 100%;
    table-layout: fixed;
}

.market-history tr {
    float: left;
    clear: both;
    overflow: hidden;
}

.market-history {
    border: 1px solid #e0e3eb;
    border-radius: 2px;
}

.market-news {
    border: 1px solid #e0e3eb;
    border-radius: 2px;
}

.market-news li a {
    color: #4a4a4a;
    font-size: 11px;
    display: block;
    text-decoration: none;
}

.market-news li:hover {
    background: #f6f8f9;
}

.market-news li strong {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
    color: #4a4a4ac7;
}

.market-news li span {
    display: block;
    margin-top: 5px;
}

.market-news li {
    border-bottom: 1px solid #f0f3fa;
    padding: 10px 13px;
}

.market-news li:last-child {
    border: 0;
}

.market-news ul {
    height: 348px;
    overflow-y: auto;
}

.market-order th {
    width: 13%;
}

.no-data {
    position: absolute;
    right: 48%;
    top: 48%;
    text-align: center;
    color: #b9c2ca;
}

.no-data i {
    font-size: 100px;
    display: block;
    line-height: 0px;
    color: #dce1e5;
}

.market-order-item {
    color: #758696;
    padding: 8px 15px;
}

.market-order {
    border-top: 0;
    min-height: 392px;
    border: 1px solid #e0e3eb;
    border-radius: 2px;
}

.markets-chart > div {
    width: 110%;
    margin-left: -16px;
}

.markets-container {
    position: relative;
    overflow: hidden;
    height: 159px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    margin-bottom: 30px;
}

.markets-chart {
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0;
}

.markets-content span.green,
.markets-content span.red {
    position: absolute;
    right: 0;
    background: #26a69a;
    color: #fff;
    top: 15px;
    padding: 3px 12px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-weight: 600;
}

.markets-content span.red {
    background: #ef5350;
}

.markets-content h2 {
    font-size: 14px;
    top: 14px;
    position: absolute;
    left: 15px;
    color: #4a4a4a;
}

.markets-content p {
    position: absolute;
    top: 32px;
    left: 15px;
    font-size: 16px;
}

.markets-pair-list tr {
    cursor: pointer;
}

.markets-pair-list th,
.markets-pair-list td {
    padding: 15px !important;
    width: 16%;
    font-size: 14px;
}

.markets-pair-list tbody tr td i {
    color: #75869696;
}



.markets-pair-list th {
    font-weight: 400;
}

.markets-pair-list td img {
    width: 18px;
    vertical-align: text-top;
    margin-right: 5px;
}

.markets-pair-list .load-more {
    border: 1px solid #e0e3eb;
    display: inline-block;
    padding: 11px 30px 8px;
    color: #4a4a4a;
    margin-top: 50px;
    transition: 0.3s;
}

.markets-pair-list .load-more i {
    margin-left: 10px;
}

.markets-pair-list .load-more:hover {
    background: #007bff;
    color: #fff;
    border: 1px solid #007bff;
}

.markets-item {
    margin-bottom: 30px;
}



.markets-capital-chart {
    margin-left: -10px;
}




.markets-capital-details h3 {
    font-size: 18px;
    margin-bottom: 40px;
}



.error-page h2 {
    font-size: 250px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1;
}

.error-page p {
    font-size: 16px;
    margin-bottom: 40px;
}

.error-page a {
    border: 1px solid #e0e3eb;
    display: inline-block;
    padding: 11px 30px 8px;
    color: #4a4a4a;
    transition: .3s;
}

.error-page a:hover {
    background: #007bff;
    color: #fff;
    border: 1px solid #007bff;
}

.error-page a i {
    margin-left: 10px;
}

.technical-analysis .tradingview-widget-container {
    margin-bottom: 30px;
}

.symbol-info .tradingview-widget-container {
    margin-bottom: 15px;
}

.markets-overview .tradingview-widget-container {
    margin-bottom: 30px;
}







#dark,
#dark header {
    background: #131722;
}

#dark header nav a {
    color: #ffffff;
    font-size: 16px;
}

#dark header nav a:hover {
    color: #007bff;
}

#dark header nav a.btn:hover {
    color: #ffffff;
}

#dark header nav .nav-item.active a {
    color: #007bff;
}

#dark .market-pairs {
    border: 1px solid #2a2e39;
    background: #131722;
}

#dark tr {
    background: #151519;
}

#dark .market-pairs .table td,
#dark .market-pairs .table th,
#dark .landing-feature {
    border-top: 1px solid #2a2e39;
    border-bottom: 0;
}

#dark .market-history .table td,
#dark .market-history .table th {
    border-top: 1px solid #2a2e39;
    border-bottom:0 ;
}

#dark .order-book thead tr {
    border-bottom: 1px solid #2a2e39;
}

#dark .order-book,
#dark .market-history,
#dark .market-news,
#dark .landing-feature-item {
    border: 1px solid #2a2e39;
}
.dark .market-pairs input, .dark .market-pairs span {
    background: #2a2e39;
    border-color: #2a2e39;
}
#dark tbody tr:hover {
    background: #2a2e39;
}



#dark .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #69727a;
}

#dark .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #69727a;
}

#dark .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #69727a;
}

#dark .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #69727a;
}

#dark tbody tr {
    color: #ffffff;
}

#dark thead th,
#dark .market-pairs .nav-link {
    color: #4f5966;
}

#dark .market-pairs .nav-link.active,
#dark #STAR i {
    color: #ffffff;
    border: 0;
}

#dark .green {
    color: #26de81;
}

#dark .red {
    color: #ff231f;
}

#dark .market-pairs .nav {
    background: #1c2030;
}

#dark .market-pairs input,
#dark .market-pairs span {
    border-color: #2a2e39;
    background: #2a2e39;
}

#dark .market-pairs input {
    color: #ffffff;
}

#dark .heading {
    background: #1c2030;
    color: #ffffff;
}

#dark .ob-heading tr {
    border-top: 1px solid #2a2e39 !important;
    border-bottom: 1px solid #2a2e39 !important;
}

#dark .ob-heading tr:hover {
    background: #131722;
    cursor: default;
}

#dark .ob-heading span {
    color: #4f5966;
}

#dark .dropdown-menu {
    background: #131722;
    box-shadow: 0 0 10px 0 #131722;
    border: 1px solid #2a2e39;
}

#dark .dropdown-item:focus,
#dark .dropdown-item:hover {
    /* background-color: #2a2e39; */
    color: #ffffff;
}

#dark .market-history .nav {
    background: #1c2030;
}

#dark .market-history .nav-link.active {
    color: #ffffff;
    border: 0;
}

#dark .market-history .nav-link {
    color: #4f5966;
}

#dark .red-bg-80:after,
#dark .red-bg-60:after,
#dark .red-bg-40:after,
#dark .red-bg-20:after,
#dark .red-bg-10:after,
#dark .red-bg-8:after,
#dark .red-bg-5:after,
#dark .red-bg:after {
    background: #ff231f63;
}

#dark .market-news {
    background: #131722;
}

#dark .market-news li {
    border-bottom: 1px solid #2a2e39;
}

#dark .market-news li:hover {
    background: #2a2e39;
}

#dark .market-news li strong,
#dark .market-news li a {
    color: #c5cbce;
}

#dark .market-order {
    background: #131722;
    position: relative;
}

#dark .no-data i,
#dark .no-data {
    color: #2a2e39;
}

#dark .market-trade {
    border: 1px solid #2a2e39;
    background: #131722;
}

#dark .market-trade .nav .nav-item .nav-link.active {
    background: transparent;
    color: #ffffff;
    border: 0;
}

#dark .market-trade .nav .nav-item .nav-link {
    color: #4f5966;
}

#dark .market-trade p {
    color: #c5cbce;
}

#dark .market-trade-list li a {
    background: #2a2e39;
    color: #ffffff;
}

#dark .market-trade input {
    border: 1px solid #2a2e39;
    background: #2a2e39;
    color: #ffffff;
}

#dark .market-trade .input-group-text {
    background: #2a2e39;
    color: #ffffff;
    border-color: #2a2e39;
    border-radius: 0 5px 5px 0;
}
.market-history .nav-link {
    color: #4a4a4a;
    padding: 10px 13px;
}
#dark .market-trade-buy .market-trade-list li a:hover {
    background: #26de81;
    color: #fff;
}

#dark .market-trade-sell .market-trade-list li a:hover {
    background: #ff231f;
    color: #fff;
}

#dark #darkDepthChart {
    background: #131722;
}

#dark .markets {
    background: #131722;
}

#dark .table td,
#dark table th {
    border-top: 1px solid #2a2e39;
}

#dark .markets-pair-list .nav {
    background: #1c2030;
}

#dark .markets-pair-list .nav-link.active,
#dark #STAR i {
    color: #ffffff;
}

#dark thead th,
#dark .markets-pair-list .nav-link {
    color: #4f5966;
}

#dark .markets-pair-list .load-more {
    border: 1px solid #2a2e39;
    color: #ffffff;
}

#dark .markets-pair-list .load-more:hover {
    color: #fff;
    border: 1px solid #007bff;
}

#dark .markets-content h2,
#dark .markets-content p {
    color: #ffffff;
}

#dark .markets-content span.green,
#dark .markets-content span.red {
    color: #fff;
}

#dark .market-trade-sell {
    border-left: 1px solid #2a2e39;
}

#dark .markets-container {
    background: #1e222d;
}

#dark .sign-up-form h3 {
    border-top: 0;
    margin-top: 0;
    padding-top: 15px;
}

#dark header nav .nav-item.active a.btn:hover {
    color: #fff;
}

#dark .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

#dark .dropdown-menu::before {
    background: #131722;
    border-top: 1px solid #2a2e39;
    border-left: 1px solid #2a2e39;
}

#dark .dropdown-menu p {
    color: #ffffff;
}

#dark .dropdown-header {
    border-bottom: 1px solid #2a2e39;
}

#dark .dropdown-footer {
    border-top: 1px solid #2a2e39;
}

#dark .header-img-icon .profile-nav .nav-item .nav-link:hover {
    background-color: #2a2e39;
    color: #ffffff;
}

#dark .form-control,
#dark .settings-profile select {
    background-color: #2a2e39;
    border: 1px solid #2a2e39;
    color: #ffffff;
}

#dark .form-access form {
    padding: 30px;
    border-radius: 5px;
    min-width: 350px;
    box-shadow: 0 0 10px 0 #131722;
    border: 1px solid #2a2e39;
}

#dark .form-access h2,
#dark .form-access {
    color: #ffffff;
}

#dark .form-access .custom-control-label::before {
    border: #2a2e39 solid 1px;
    background-color: #2a2e39;
}

#dark .error-page a {
    border: 1px solid #2a2e39;
    color: #ffffff;
}

#dark .error-page a:hover {
    border: 1px solid #007bff;
    background: #007bff;
    color: #ffffff;
}

#dark .error-page h2,
#dark .error-page p {
    color: #c5cbce;
}



#dark .depth-chart-inner {
    border: 1px solid #2a2e39;
}

#dark .btn-1:hover,
#dark .btn-2:hover,
#dark .landing-hero,
#dark .landing-info-one,
#dark .landing-feature,
#dark footer.landing-footer ul li a {
    color: #fff;
}



#dark .landing-feature-item img {
    filter: invert(1);
}

#dark footer.landing-footer-two {
    background: #2a2e39;
    color: #fff;
    border-color: #2a2e39;
}

#dark footer.landing-footer-two ul li a {
    color: #ffffffbd;
}

#dark footer.landing-footer-two ul li a:hover {
    color: #007bff;
}

@media only screen and (max-width: 1434px) {
    .market-pairs tbody {
        height: 760px;
    }
    .no-fluid .market-pairs tbody {
        height: 815px;
    }
    .settings-profile .custom-file {
        width: 25%;
    }
}

@media only screen and (max-width: 1300px) {
    .market-pairs tbody {
        height: 740px;
    }
    .no-fluid .market-pairs tbody {
        height: 755px;
    }
}




@media only screen and (max-width: 918px) {
    .market-history tbody {
        height: 274px;
    }
}

@media only screen and (max-width: 792px) {
    .market-pairs tbody {
        height: 699px;
    }
}

.inner_header {
    padding: 24px 0;
    background-color: #00000024;
    color: #fff;
}

.inner_heading i {
    font-weight: 700;
    margin-right: 15px;
}

.inner_heading h3 {
    font-size: 24px;
}


.breadcrum_btn a {
    background: #000;
    padding: 6px 25px;
    border-radius: 5px;
    font-size: 16px;
}

.breadcrum_btn i {
    background-color: #fff;
    height: 20px;
    width:20px;
    color:#000;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
}

.breadcrum_btn i {
    margin-left: 5px;
}

.box_heading {
    padding: 40px 10px;
    color: #fff;
}



.campus_img {
    background-color: #000;
    width: 100%;
    border-radius: 10px;
}

.campus_img img {
    border-radius: 10px;
}
.campus_box h2 {
    color:#fff;
    margin-right:15px;
}

.campus_box .table td {
    border-top: 0!important;
    border-bottom: 1px solid #2a2e39;
    padding: 18px 15px;
}

.campus_box .table td i {
    margin: 0 5px;
}


.campus_box .table th {
    border-top: 0!important;
    border-bottom: 1px solid #2a2e39;
    padding: 18px 15px;
    background-color: #000;
}

.launch_icon li a {
    background-color: #68686863;
    margin-right:15px;
    padding: 5px 10px;
    border-radius: 5px;
}

.launch_icon li a i {
    margin-right: 10px;
}

.investment_bg {background: #10141d;
    margin-top: 50px;
    padding: 30px 30px;
    border-radius: 10px;
}

.investment_para h4 {
    margin: 40px 0;
}

.investment_para h5 {
    margin: 30px 0;
    color: #fff;
}

.investment_para p {
    color:#ababab;
    line-height: 25px;
}


.social_channel {
    margin: 40px 0;
    color: #fff;
}

.social_channel i {
    color:#000;
    background-color: #fff;
    height: 30px;
    width: 30px;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}
.social_channel li {
    margin: 20px 0;
    font-size: 18px;
}



.detail .table tr td {
    padding: 15px 15px;
    border-bottom: 0;
}

.detail .table tr th {
    padding: 15px 15px;
    border-bottom: 0;
}

#example1_filter label {
    color:#fff;
}
#example2_filter label {
    color:#fff;
}

.box {
    padding: 30px 30px;
    background: #151519;
    border-radius: 10px;
}

 .sorting_1 {
    color:#b7bbc0!important;
    background: #171e30;
  }


  #example {
    margin-top: 30px!important;
  }

  #example2 a {
    color:#fff;
    font-size: 14px;
  }


  #example  td {
    color:#b7bbc0;
    border-right: 0;
    background: #151519;
  }

  #example  th {
    color:#fff!important;
    /* background: #171e30; */
    padding: 15px 15px!important;
  } 

  #example_filter label {
    color:#fff;
  }

  






  #example1 {
    margin-top: 30px!important;
  }
  #example2 {
    margin-top: 30px!important;
  }

  #example1  td {
    color:#b7bbc0;
    border-right: 0;
    background: #151519;
    border-left:0 ;
  }

  #example2  td {
    color:#b7bbc0;
    border-right: 0;
    background: #151519;
  }

  #example1  th {
    color:#fff!important;
    /* background: #171e30; */
    padding: 15px 15px!important;
  } 

  #example2  th {
    color:#fff!important;
    /* background: #171e30; */
    padding: 15px 15px!important;
  } 



  .dataTables_length label {
   
    color: #fff;
}

.dataTables_info {
    color:#fff;
}

.page-link {
    color:#000;
}

.active>.page-link {
    background-color: #a8bbc0;
    border-color: #a8bbc0;
    border-radius: 0;
}
/* Timeline */


 .card {
    background: #1e222d;
}

.card-title {
    color: #fff;
    margin-bottom: 20px;
}

.form-select {
    background: #2a2e39;
    border: 0;
    color: #69727a;
}

.card-body .form-control {
    margin-bottom: 20px;
}


.depost_detail .table tr {
    background-color: inherit!important;
}

.depost_detail .table tr td {
    color:#fff;
    text-align: right;
    border-top: 0px!important;
    border-bottom: 1px solid #2a2e39!important;
}

.depost_detail .table tr th {
    color:#fff;
    border-top: 0px!important;;
    border-bottom: 1px solid #2a2e39!important;
}


.wallet_top ul li {
    margin-left: 10px;
}

.wallet_top ul li a {
    color:#fff;
    background-color: #000;
}

.wallet_top ul li a:hover {
    background-color: #000;
    color:#fff;
}

.estimate_balance  {
    border-bottom: 1px solid #2a2e39;
    margin: 20px 0;
    padding: 20px 0;
}

.estimated_b {
    color:#fff;
}

.estimated_b h5 {
    font-size: 15px;
    color: #b7bbc0;
}

.estimated_b h5 span {
    font-size: 13px;
}

.myreferral {
    background-color: inherit!important;
    border: 0!important;
}

.myreferral ul {
    background-color: #151519;
}

.refer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    text-align: center;
    color:#fff;
}

.refer i {
    font-size: 80px;
}

.journey_box {
    background-image: linear-gradient(#0d6efd33, #151519);
    padding: 50px;
    border-radius: 10px;
    margin-top: 10px;
}

.forward i {
    background-color: #0069d9;
    height: 30px;
    width: 30px;
    margin-right: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}

.gift_card {
    background: #000;
    width: 100%;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 12px;
}

.gift_card span {
    background-color:#10141d;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    margin-right: 10px;
    border-radius: 50%;
}

.accordion-item {
    background-color: inherit;
    border: 0;
}

.security {
    display: flex;
    margin-top: 30px;
}

.security li {
    margin-right: 20px;
    font-size: 14px;
}

.security li i {
    background-color: #007bff;
    height: 15px;
    width: 15px;
    font-size: 8px;
    text-align: center;
    line-height: 15px;
    font-weight: 400;
    border-radius: 50%;
    margin-right: 5px;
}


.security1 p {
    margin: 0;
}

.security1 i {
    background-color: #38393c;
    padding: 5px;
    font-size: 8px;
    border-radius: 50%;
    margin-right: 5px;
}

.detail_text {
    background: #496fc8;
    padding: 15px 15px;
    color: #fff;
    border-radius: 5px;
    margin: 50px 0;
}


.detail_box i {
    font-size: 80px;
    color: #fff;
    
}
.box_text {
    border-left: 1px solid #fff;
    padding: 0 15px;
    color: #fff;
}

.box_text img {
    width: 35%;
}



/* Form Wizard */



#wizard_container {
	width: 400px;
    margin:0 auto;
}

@media (max-width:767px) {
	#wizard_container {
		width: 100%
	}
}

h3.main_question {
	margin: 0 0 20px 0;
	padding: 0;
	font-weight: 500;
	font-size: 18px;
	font-size: 1.125rem
}

h3.main_question strong {
    display: block;
    color: #fff;
    margin-bottom: 5px;
    background: #496fc8;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    
}

button.backward,
button.forward,
button.submit {
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: 0;
	text-align: center;
	background: #434bdf;
	position: relative;
	font-size: 14px;
	font-size: .875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 30px
}

button.backward {
	color: #777;
	background: #e8e8e8
}

button[disabled] {
	display: none
}

button.submit:before {
	content: "\4e";
	font-family: ElegantIcons;
	position: absolute;
	top: 8px;
	right: 10px;
	font-size: 18px;
	font-size: 1.125rem
}

.backward:hover,
.forward:hover {
	background: #d80075;
	color: #fff
}

#top-wizard {
	padding-bottom: 20px
}

#middle-wizard {
	min-height: 330px
}

@media (max-width:991px) {
	#middle-wizard {
		min-height: inherit
	}
}

#bottom-wizard {
	text-align: right;
	margin-top: 25px
}


.history_list .dropdown-menu {
    background-color: #fff!important;
    border:0!important;
    
}

.history_list .dropdown-menu li {
    margin: 0;
    padding: 0 10px;
}

.history_list .dropdown-menu li .form-control {
    padding-left: 30px;
    font-size: 14px;
} 

.history_list .dropdown-menu li i {
    position: absolute;
    top: 18px;
    left:18px;
    color:#666;
}

.history_list .dropdown-menu .dropdown-item:hover {
    background-color: inherit;
    color:#212529!important;
}

.history_list .dropdown-menu .dropdown-item {
    padding: 5px 0;
}

.history_list .dropdown input {
    background-color: inherit;
}

.drop_Date {
    background-color: inherit!important;
    border:0px!important;
    padding: 0;

}

.btn_btn img {
    height: 30px;
    width: 30px;
}

.coin_body ul li a {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.coin_body ul li:hover {
    background-color: #18191d;
}

.coin_body ul li img {
    height: 30px;
    width:30px;
    margin-right: 10px;
}


.modal-header {
    background-color: #18191d;
    color: #a0a9b2;
    border-radius: 0;
    border-bottom: 1px solid #485063;
}

.modal-body {
    background-color: #1b202b;
    color: #a0a9b2;
}

.btn-close {
    background-image: url(/public/banner/times.png);
}

.care {
    top: 45px!important;
    right: 10px!important;
}


.w_F img {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}




/* End Wizard */






