
/* Banner */

.box_text a {
    color: #0d6efd;
    margin: 10px 0;
    border-bottom: 1px solid #0d6efd;
}

body {
    background-color: #18191d!important;
}

.banner {
    background: #141416;
    padding: 84px 0 145px;
}

.banner__content .title {
    line-height: 1.11;
    letter-spacing: -1.2px;
    margin-bottom: 24px;
    font-size: 70px;
    color: #fff;
}

.banner__content .desc {
    letter-spacing: -0.2px;
    padding-right: 70px;
    color: #b1b5c3;
    font-size: 18px;
}

.banner__content .btn-action {
    color: #fff;
    margin-top: 48px;
}


.banner__content .btn-primary {
    padding: 13px 30px;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    position: relative;
    overflow: hidden;
    font-weight: 500;
}


/* End Banner */

.about__content .heading1 {
    margin-bottom: 16px;
    font-size: 30px;
    color:#fff;
}

.about__content p {
    color: #b1b5c3;
    margin-top: 20px;
}


.fs-20 {
    font-size: 16px;
}

.span_about {
    font-size: 18px;
    color:#fff;
    margin-bottom: 5px;
}

.work {
    padding: 60px 0;
    background: #10141d;
}

.block-text {
    color:#fff;
}

.block-text  p {
    font-size: 20px;
}

.block-text .heading1 {
    margin-bottom: 16px;
    font-size: 48px;
}

.work .block-text .desc {
    padding: 0 350px;
    text-align: center;
}

.work .work__main {
    display: flex;
    margin-top: 50px;
}

.work .work__main .work-box {
    width: 33%;
    text-align: center;
    margin-right: 70px;
    position: relative;
    margin: 0 auto;
}

.work .work__main .work-box .image {
    margin-bottom: 12px;
}

.work .work__main .work-box .image img {
    transition: 0.5s all ease-in-out;
}

.work .work__main .work-box .content .step {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: #b1b5c3;
    text-align: center;
}

.work .work__main .work-box .content .title {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.work .work__main .work-box .content .text {
    letter-spacing: -0.1px;
    padding: 0 18px;
    text-transform: capitalize;
    color: #b1b5c3;
    margin-top: 8px;
    font-size: 16px;
}

.work .work__main .work-box .line {
    position: absolute;
    right: -115px;
    top: 40px;
    z-index: 0;
}

.window .nav-link {
    color:#fff;
    font-size: 18px;
}

.window .nav-link:hover {
    border: 0;
}

.window .nav-tabs .nav-link.active {
    border-radius: 0;
    background-color: #0d6efd;
    color:#fff;
    border: 0;
    padding: 8px 25px;
    border-radius: 25px;
    
}
.about__content ul {
    color:#fff;
    margin: 40px 0;
}

.about__content ul li {
    margin: 15px 0;
    font-size: 15px;
}

.about__content ul li i {
    margin-right: 10px;
    background-color: #0d6efd;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

/* Footer */

.footer {
    padding: 50px 0 30px;
        background: #141416;
        position: relative;
        border-top: 1px solid transparent;
        background-position: 50% 23%;
        background-size: cover;
        bottom: 0;
        width: 100%;
    }


    .app_logo a {
        margin-right: 15px;
    }

    .app_logo a img {
        margin-bottom: 15px;
    }

    .footer-social-icons ul {
        padding-top: 20px;
    }

    .footer-social-icons ul li {
        background: #fff;
        height: 35px;
        border-radius: 50%;
        width: 35px;
        line-height: 30px;
        padding: 3px;
        text-align: center;
        margin-right: 5px;
        
    }

    .footer-social-icons ul li a {
        color: #24292d !important;
    }
 
    .footer {
        color: #fff;
        font-size: 16px;
    }

    .payment-cls-btm {
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        margin-top: 15px;
        padding: 0;
    color: #fff;
    line-height: 50px;
}

.payment-cls-btm {
  
    margin: 0px;
}

.payment-cls-btm ul li {
    width: 20%;
    text-align: center;
}

.footer ul li a {
    
    color: #fff;
    line-height: 29px;
}

.market_table .table td img  {
    background-color: #fff;
    height: 30px;
    width: 30px;
    padding: 3px;
    border-radius: 25px;
}

.market_table .table td {
    font-size: 16px;
    font-weight: 500;
    padding: 30px 25px;
    text-align: center;
}
.market_table .table tr {
    border-bottom: 1px solid #2a2e39;
}

.market_table .table th {
    text-align: center;
    font-size: 18px;
    color:#fff;
    font-weight: 700;
    border-bottom: 1px solid #fff!important;
}

.product_box {
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    margin-bottom: 15px;
    height: 300px;
}

.product_box i {
    color: #141416;
    font-size: 36px;
}

.product_box h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #141416;
}
.product_box p {
    line-height: 27px;
    max-width: 350px;
    margin: 0 auto 30px;
    color: #141416;
}


.our_products {
    background-color: #141416;
    padding: 84px 0 60px;
}

.product_box .btn-primary {
    background-color: #141416;
}

.portfolio .media {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.portfolio .port-icon {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-right: 30px;
    color: #fff;
    background: #3772ff;
    box-shadow: 0px 0px 18px 0px #3772ff;
}

.portfolio .media-body {
    flex: 1;
}

.portfolio .media h4 {
    font-size: 18px;
    color: #fff;
}

.portfolio .media p {
    margin-bottom: 0px;
    color: #fff;
}

.app_screen {
    cursor: pointer;
    text-align: center;
    background-color: #141416;
    border-radius: 8px;
    padding: 55px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex: 1 0;
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 10px 0px #393939;
}

.app_screen:before {
    content: '';
    display: block;
    opacity: 0.06;
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.app_screen h4 {
    color: #fff;
    margin-bottom: 40px;
    line-height: 30px;
    font-size: 20px;
}

.app_area {
    width: 250px;
}

.app_area img {
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px 0px 10px 0px #ccc;
}

.feature_img {
    margin: 0 auto;
    width: 70%;
}

.feature_img img {
    border-radius: 10px;
}



/* .current_date h1 {
    color:rgb(234, 236, 239);
    font-size: 48px;
}

.current_date p {
    color:rgb(183, 189, 198);
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
} */





.just {
    background: #151519;
    padding: 15px;
    text-align: center;
    color: #fff;
}

.just_box:hover {
    box-shadow: 0px 0px 10px 0px #88878730;
}

.just p {
    margin: 0;
}

.just_box {
    padding: 40px;
    border:1px solid #3d3d46;
}

.just_box h3 {
    color:#fff;
    margin-bottom: 20px;
}

.just_box p {
    color:#fff;
}

.just_btn a {
    background-color: #2b2b2b;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
    color: #fff;
}

.suggestion_box {
    color: #fff;
    padding: 150px 0;
}

.suggestion_box h3 {
    margin: 20px 0;
}

.suggestion_box p {
    font-size: 16px;
    margin: 20px 0;
}

.affilate_box {
    padding: 35px 35px;
    color: #fff;
    margin-top: 40px;
    border-radius: 10px;
}

.affilate_box:hover {
    background-color: #171e30;
}

.affilate_box h5 {
    background-color: #0d6efd;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

.affilate_box:hover h5 {
    border:3px solid #fff;
    line-height: 32px;
}

.n_table .table tr td img{
    height: 25px;
    width: 25px;
    border-bottom: 0;
    margin-right: 10px;
}

.n_table .table tr td {
    padding: 12px 15px;
    background-color: #151519;
    border-bottom: 0;
}

.n_table p {
    color: #707A8A;
}

#example1  tr td a {
    margin-right: 15px;
}

#example1  tr td img {
    height: 25px;
    width: 25px;
}

#example1  tr td h6 {
margin: 0 10px!important;
}

.buy_box {
    background: #151519;
    padding: 20px 30px;
    border-radius: 15px;
}
.snm_price_cal h3 {
    color:#fff;
    padding: 15px 0;
}


.snm_img {
    background: #18191d;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
    justify-content: space-around;
}

.snm_img img {
    height: 30px;
    width: 30px;
}
.coinbase {
    background: #151519;
    padding: 20px;
    color: #fff;
    border-radius: 10px;
}

.coinbase i {
    background: #0d6efd;
    padding: 5px 10px;
    border-radius: 50%;
}

.r_snm .table tr td {
    border-bottom: 0!important;
}

.progress {
    height: 5px;
}

.progress-bar {
    height: 5px;
}

.withdrawl_table td {
    border: 0!important;
    padding: 5px 0px;
}

.withdrawl_table th {
    border: 0!important;
    padding: 5px 0px;
}



.withdrawl_table tr {
    background-color: inherit!important;
}

.bg_new {
    background-color: #151519;
    padding: 30px;
}

.crypto_tab .nav-link {
    color:#fff;
}

.history_list h6 {
    color:#fff;
}

.history_list ul li {
    margin-right: 15px;
}


.detail_icon {
    background-color: #000;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    font-weight: 700;
}

.navbar-toggler-icon {
    background-image: url(/public/banner/bar.png);
}

.resp_drop {
    display: flex;
}

.profile_img {
    
    width:50%;
}

.verify p {
    margin: 3px 0 0;
    border-radius: 15px 0px 0 15px;
}

.verify img {
    height: 18px;
    width: 18px;
    margin-right: 2px;
}

.about__content p {
    text-align: justify;
}

.about__content .table td {
    border: 0;
}

.error {
    position: relative;
}
.error_box {
    text-align: center;
    padding: 100px 0;
    color: #fff;
}

.error_box h1 {
    font-size: 150px;
}
.error_box h6 {
    margin: 0 0;
}

.wallet {
    height: 20px;
    margin-top: -2px;
}

.summary .dropdown-menu  .dropdown-item {
    border-bottom: 1px solid #2a2e39;
    width:250px;
    padding: 8px 15px 8px;
}

.dropdown-item img {
    height: 20px;
    width: 20px;
}


.d-resp {
    display: none;
}

.dataTables_length {
    display: none;
}
.dropdown-item:hover {
    background-color: #0d6efd;
}

.accordion-button {
    background-color: #151519!important;
    color:#fff!important;
}
.accordion-button:not(.collapsed) {
    box-shadow: inherit;
}

.accordion-body {
    color:#b1b5c3;
}