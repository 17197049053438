@media screen and (min-device-width: 320px) and (max-device-width: 767px) { 
    .app_screen {
        margin-bottom: 30px;
        height: inherit!important;
    }

    .resp_drop {
        display: block!important;
    }

    .resp_drop button {
        padding: 0;
    }

    .navbar-brand {
        width:35%;
    }

    .banner__content .title {
        font-size: 55px!important;
    }

    .about_img {
        margin-top: 40px;
    }
    .block-text .heading1 {
        font-size: 36px!important;
    }

    .app_logo {
        margin-top: 20px;
    }
    .about__content {
        margin: 25px 0;
        text-align: center;
    }

    #dark header {
        position: absolute;
        width: 100%;
        z-index: 9999;
        top: 0;
        box-shadow: 0px 0px 8px 0px #ffffff82;
    }

    .profile a {
        width: 100%;
        text-align: left;
        padding: 10px 0px 10px;
    }
    .profile_img {
        width:10%;
    }

    .navbar-nav .nav-link {
        font-size: 18px;
    }
    #dark .market-pairs {
        margin-top: 60px;
    }

    .campus_img {
        margin-bottom: 30px;
    }
    .inner_header {
        margin-top: 60px;
    }

    .launch_icon {
        flex-wrap: wrap;
    }

    .launch_icon li {
        margin: 10px 0;
    }

    .investment   {
        margin-top: 12px;
    }
    .history_list ul {
        flex-wrap: wrap;
    }

    .history_list ul li {
        margin: 10px 0;
    }

    .btn_link {
        margin-top: 15px;
    }


    .security {
        display: block;
        padding:0 10px;
    }

    .security li {
        margin: 10px 0;
        font-size: 18px;
    }

    .refer {
        width:100%;
    }

    .work .work__main .work-box {
        width:100%!important;
    }

    .work .work__main {
        display: block!important;
    }
    .work .work__main .work-box .line {
        display: none;
    }

    .t_content {
        width: 100%!important;
    }
    .d-desk {
        display: none;
    }
    .d-resp {
        display: block!important;
    }

    .d-resp .btn-secondary {
        background-color: inherit;
        border: 0;
        padding: 0;
    }

    #dark .dropdown-menu {
        width:100%;
    }


    .navbar-nav {
        margin-top: 30px;
        margin-bottom: 0!important;
    }

    .navbar-nav .nav-item {
        /* border-bottom: 1px solid #fff; */
        border-top: 1px solid #393c48;
    }

    .navbar-nav .nav-link {
        padding: 10px 0px;
    }
    .summary {
        border-top: 1px solid #393c48;
        padding: 10px 0px;
    }

    .profile .btn-secondary  {
        border-top: 1px solid #393c48;
        border-radius: 0;
    }
    .about__content ul {
        text-align: left;
    }

    .wallet_top ul {
        flex-wrap: wrap;
    }
    .wallet_top ul li {
        margin-bottom: 10px;
    }

    .payment-cls-btm  ul {
        flex-wrap: wrap!important;
    }

    .payment-cls-btm ul li {
        width:inherit!important;
    }

}